<template>
  <vx-card title="Import CMI">
    <cmi-to-inv></cmi-to-inv>
    <!-- <vs-tabs>
      <vs-tab label="Sales Order To Invoice">
        <div class="tab-text">
          <so-to-invoice></so-to-invoice>
        </div>
      </vs-tab>
      <vs-tab label="Customer Return Reference">
        <div class="tab-text">
          <sr-reference></sr-reference>
        </div>
      </vs-tab>
      <vs-tab label="Payment">
        <div class="tab-text">
          <payment></payment>
        </div>
      </vs-tab>
    </vs-tabs> -->
  </vx-card>
</template>
<script>
import CmiToInv from "./cmi_to_inv.vue";
export default {
  components: {
    "cmi-to-inv" : CmiToInv,
  }
};
</script>